<template>
	<div class="billing-form-component" :class="$responsive">
		<p class="subtitle">
			Completa la información con los datos de facturación para tu servicio
		</p>
		<form class="billing-form" method="post" action="#" @submit.prevent="submitForm">
			<section class="billing-section" v-for="(section, sectionIndex) in form" :key="sectionIndex">
				<h3 class="billing-section__title" v-if="section.title">
					{{ section.title }}
				</h3>
				<div class="billing-section__optional" v-if="section.optional">
					<input type="checkbox" v-model="section.optional.value">
					<span>{{ section.optional.description }}</span>
				</div>
				<p class="billing-section__description" v-if="section.description">
					{{ section.description }}
				</p>
				<div v-show="showSection(section)">
					<div v-if="section.fields">
						<div v-for="(field, key, index) in section.fields" :key="key">
							<form-field :index="index" :field="field" v-model.trim="field.value" />
						</div>
					</div>
				</div>
				<div class="billing-comments" v-if="section.comment">
					{{ section.comment }}
				</div>
				<div class="billing-section__error-message" v-if="section.errorMessage">
					{{ section.errorMessage[0] }}
				</div>
			</section>
			<base-button class="billing-form__submit">
				Enviar Información
			</base-button>
			<div class="billing__error-msg" v-show="!errorFree">
				Revisa la información y completa los campos necesarios para los datos de facturación.
			</div>
			<re-captcha ref="recaptcha" @verify="doSubmit"></re-captcha>
		</form>
	</div>
	<modal-processing v-if="isSending">
		Enviando tu información..
	</modal-processing>
</template>

<script>
const axios = require('axios');
import reCaptcha from "@/components/ReCaptchaV2.vue";
import modalProcessing from "@/components/ModalProcessing.vue";
import baseButton from "@/components/BaseButton.vue";
import formField from "@/components/FormField";
import useForm from "@/forms.js";
//import baseInputFile from "@/components/BaseInputFile";

export default {
	name: "SignupForm",
	components: {
		reCaptcha,
		modalProcessing,
		baseButton,
		formField,
	},
	emits: ['sent'], // new on Vue3
	setup() {
		const { checkSections } = useForm();
		return { checkSections };
	},
	data() {
		return {
			form: [
				{
					title: "Datos de la liga",
					description: "Escribe los datos generales de la liga.",
					fields: {
						leagueName: {
							value: "",
							type: "text",
							label: "Nombre de la Liga",
							required: true,
							errorMessage: []
						},
					}
				},	
				{
					title: "Datos de facturación",
					description: "Información y datos fiscales para la emisión de tus facturas y complementos de pago.",
					fields: {
						rfc: {
							value: "",
							type: "text",
							label: "RFC",
							required: true,
							errorMessage: []
						},
						razonSocial: {
							value: "",
							type: "text",
							label: "Razón Social",
							required: true,
							errorMessage: []
						},
						regimenFiscal: {
							value: "",
							type: "select",
							label: "Régimen fiscal",
							values: [null, '601	General de Ley Personas Morales', '603	Personas Morales con Fines no Lucrativos', '605	Sueldos y Salarios e Ingresos Asimilados a Salarios', '606	Arrendamiento', '611	Ingresos por Dividendos (socios y accionistas)', '612	Personas Físicas con Actividades Empresariales y Profesionales', '616	Sin obligaciones fiscales', '621	Incorporación Fiscal', '626	Régimen Simplificado de Confianza'],
							required: true,
							errorMessage: [],
							helpText: "Requerido para CFDI 4.0"
						},
						postalCode: {
							value: "",
							type: "text",
							label: "Código postal",
							required: true,
							errorMessage: []
						},
						address: {
							value: "",
							type: "text",
							label: "Calle",
							required: true,
							errorMessage: []
						},
						numberExt: {
							value: "",
							type: "text",
							label: "No. exterior",
							required: true,
							errorMessage: []
						},
						numberInt: {
							value: "",
							type: "text",
							label: "No. interior",
							required: false,
							errorMessage: []
						},
						address2: {
							value: "",
							type: "text",
							label: "Colonia",
							required: true,
							errorMessage: []
						},
						city: {
							value: "",
							type: "text",
							label: "Ciudad/Delegación",
							required: true,
							errorMessage: []
						},
						state: {
							value: "",
							type: "text",
							label: "Estado",
							required: true,
							errorMessage: []
						},
						email: {
							value: "",
							type: "text",
							label: "Correo(s) para recibir facturas",
							required: true,
							errorMessage: [],
							helpText: "Incluye uno o más correos separados por comas."
						}
					}
				},
				{
					fields: {
						clientComments: {
							value: "",
							type: "textarea",
							label: "Comentarios",
							rows: 2,
							required: false,
							errorMessage: []
						},
					}
				}
			],
			attachment: {
				file: '',
				error: false
			},
			sent: false,
			isSending: false,
			errorFree: true
		}
	},
	methods: {
		showSection(section) {
			return !section.optional || section.optional.value;
		},
		attachFile(file) {
			this.attachment.file = file;
		},
		doSubmit(token) {
			const getFields = fields => Object.entries(fields).reduce((acc, el) => ({...acc, [el[0]]: el[1].value }), {});
			const billingData = this.form.reduce((obj, section) => ({...obj, ...getFields(section.fields || {})}), {});

			let formData = new FormData();
			formData.append("file", this.attachment.file);
			formData.append("data", JSON.stringify(billingData));
			formData.append("recaptchaToken", token);

			const config = {
				headers: {
					'content-type': 'multipart/form-data'
				}
			}

			this.isSending = true;
			axios.post('/api/billing', formData, config)
				.then(response => {
					console.log(response);
					this.sent = true;
				})
				.catch(error => console.log(error))
				.finally(() => {
					this.isSending = false;
					this.$emit('sent', this.leagueName);
				});
		},
		submitForm() {
			let checkSections = this.checkSections(this.form);
			this.errorFree = checkSections;
			if (this.errorFree) { 
				this.$refs.recaptcha.execute();
			}
		}
	}
}
</script>

<style scoped>
.billing-form-component {
	margin: 2rem auto 0;
	background-color: #ffffff;
	padding: 1rem 3rem;
	border-radius: 1rem;
}

.billing-form-component.mobile {
	padding: 1rem;
}

.billing-form-component.desktop {
	max-width: 80%;	
}

.desktop .subtitle {
	margin: 2rem auto;
	max-width: 80%;
}

.billing-form {
	font-family: 'open sans', helvetica, sans-serif;
}

.billing-form__submit {
	margin: 2.5rem auto 2rem;	
}

.billing-section {
	margin-bottom: 3.75rem;
}

.mobile .billing-section {
	margin-bottom: 4.75rem;	
}

.billing-section__title {
	font-size: .9375rem;
	text-align: left;
	margin: 0 0 .75rem;
}

.billing-section__description {
	font-size: .8125rem;
	text-align: left;
	color: var(--secondary-color);
	line-height: 1.66em;
	margin: 0 0 1.75rem;
}

.billing-section__optional {
	text-align: left;
	font-size: .8125rem;
	margin-bottom: .75rem;
	font-weight: 600;
	display: flex;
	align-items: center;
}

.billing-section__optional > span {
	margin-left: .5em;
}

.billing-comments {
	text-align: left;
	font-size: .9375rem;
	font-size: .875rem;
	border-bottom: 1px solid #efefef;
	padding-bottom: 1.5rem;
	margin-top: 2rem;
	line-height: 1.66em;
}

.billing__error-msg {
	color: var(--error-color);
	margin-bottom: 2rem;
}

.billing-section__error-message {
	color: var(--error-color);
	text-align: left;
	font-size: .875rem;
	margin-top: 1em;
}
</style>